import axios from 'axios'
import i18n from '@/i18n'
import manifest from 'public/manifest.json'
import { BASE_URL } from '@/config'

class VersionUpdater {
  constructor(Vue) {
    this.CURRENT_VERSION = manifest.version
    this.ax = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    this.userCancelUpdate = false

    this.updater()
  }

  async updater() {
    setTimeout(() => {
      axios
        .get(`/manifest.json?t=${new Date().getTime()}`)
        .catch(() => {
          // ignore errors of the updater
        })
        .then(res => {
          if (res.data.version != this.CURRENT_VERSION) {
            if (confirm(i18n.global.t('alertUpdateVersion'))) {
              window.location.reload()
            } else {
              this.userCancelUpdate = true
            }
          }
        })
        .finally(() => {
          if (!this.userCancelUpdate) {
            this.updater()
          }
        })
    }, 1000 * 60)
  }
}

export default {
  install(Vue) {
    new VersionUpdater(Vue)

    // Vue.mixin({
    //   beforeCreate: function() {
    //   },
    // })
  },
}
