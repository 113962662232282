<script setup lang="js">
import { ref } from 'vue';
import { DndProvider } from 'vue3-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const rootElement = ref(null);
</script>

<template>
  <div id="app" ref="rootElement" class="app">
    <DndProvider :backend="HTML5Backend" :options="{ rootElement }">
      <router-view />
    </DndProvider>
  </div>
</template>

<style lang="sass" src="@/assets/sass/src.sass"></style>
