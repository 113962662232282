import _ from 'lodash'
import * as GETTERS from '@/store/getters'

export default {
  viewDashboard: ({ $store, $router, $events }) => {
    const canViewDashboard = _.get(
      $store,
      'state.User.permissions.project.canViewDashboard',
      false
    )

    const useNewLayout = _.get($store, 'getters.useNewLayout', false);

    if (canViewDashboard) return

    if (useNewLayout) {
      const userId = $store.state.User.user?.id;
      $store.dispatch('Filters/handleChangeTasksTimeRange', 'year');
      $store.dispatch('Filters/patchTaskFilterLocally', {
        statuses: [false],
        users: userId ? [userId] : [],
      });
      $events.emit('updateLocalTasksFilter');

      $router.push({
        name: 'tasks',
      });

      throw `Don't access to dashboard`;
    }

    const saveProjectChat = localStorage.getItem('lastProjectChat'),
      paramsRoute = saveProjectChat ? JSON.parse(saveProjectChat) : {}

    if (paramsRoute.projectId && paramsRoute.chatId) {
      $router.push({
        name: 'projectChat',
        params: {
          ...paramsRoute,
        },
      })
    } else {
      const project = $store.getters[GETTERS.PROJECTS.MOD.projects][0]
      if (!project) {
        $router.push({ name: 'account' })
        throw `Not find projects in viewDashboard middleware`
      }

      $router.push({
        name: project.firstProjectChatId ? 'projectChat' : 'chats',
        params: {
          projectId: project.id,
          chatId: project.firstProjectChatId,
        },
      })
    }

    throw `Don't access to dashboard`
  },

  viewProjectInfo: ({ $store, $router, $route }) => {
    const canViewInfo = _.get(
        $store,
        'state.User.permissions.project.canViewInfo',
        false
      ),
      project = $store.getters[GETTERS.PROJECTS.MOD.getProjectById](
        $route.params.projectId
      )

    if (canViewInfo) return
    if (!project)
      throw `Not find project ${$route.params.projectId} in viewProjectInfo middleware`

    $router.push({
      name: project.firstProjectChatId ? 'projectChat' : 'chats',
      params: {
        projectId: project.id,
        chatId: project.firstProjectChatId,
      },
    })

    throw `Don't access to project info`
  },

  viewProjectManagementInfo: ({ $store, $router, $route }) => {
    const canViewReports = _.get(
        $store,
        'state.User.permissions.registration.canViewReports',
        false
      ),
      project = $store.getters[GETTERS.PROJECTS.MOD.getProjectById](
        $route.params.projectId
      )

    if (canViewReports) return
    if (!project)
      throw `Not find project ${$route.params.projectId} in viewProjectManagementInfo middleware`

    $router.push({
      name: project.firstProjectChatId ? 'projectChat' : 'chats',
      params: {
        projectId: project.id,
        chatId: project.firstProjectChatId,
      },
    })

    throw `Don't access to project management info`
  },
}
